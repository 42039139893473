<template>
  <div class="auth-wrapper auth px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            src="@/assets/images/logo/logo.png"
            alt="b2motor.ru"
            class="navbar-brand-full"
          /></b-link>

        <b-card-title class="mb-1">
          Подтверждение входа SMS паролем
        </b-card-title>
        <b-card-text
          v-if="step === '1'"
          class="mb-2"
        >
          Введите номер телефона зарегистрированный в системе и мы вашлем код авторизации
        </b-card-text>
        <b-card-text
          v-if="step === '2'"
          class="mb-2"
        >
          Введите код авторизации из смс, высланный на номер {{ loginPhone }}
        </b-card-text>

        <validation-observer
          v-if="step == 1"
          ref="authcode"
        >
          <b-form
            class="auth-code-form mt-2"
            @submit.prevent="validationForm"
          >
            <b-form-group
              label-for="phone"
              label="Телефон"
            >
              <validation-provider
                #default="{ errors }"
                name="телефон"
                rules="required|mobilephone"
              >
                <b-form-input
                  id="phone"
                  v-model="phone"
                  v-mask="'+7(###)###-##-##'"
                  :state="errors.length > 0 ? false:null"
                  placeholder="+79101001010"
                  autocomplete="off"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Отправить
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text
          v-if="step == 2"
          class="text-center mb-2"
        >
          <div>тел: {{ phone }} </div>
          <div>
            <b-link @click="step = 1">
              <span>cменить</span>
            </b-link>
          </div>
        </b-card-text>

        <sms-confirm
          v-if="step == 2"
          @send="onSendAuth"
          @resendcode="onResendCode"
        />
        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> Назад к авторизации
          </b-link>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import {
  ValidationProvider, ValidationObserver, localize, extend,
} from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BImg,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import mobilephone from '@/validations/mobileValidates'

import SmsConfirm from '@/components/SmsConfirm.vue'

import { sendConfirmationCode, sendAuthCode } from '@/application/authService'
import { fetchUserData } from '@/application/userService'
import { chekbasket } from '@/application/basketService'
import { fetchModeratorData } from '@/application/dashboardService'

import store from '@/store'
import router from '@/router'

localize('ru', ru)
extend('mobilephone', mobilephone)

export default {
  components: {
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    // validations
    ValidationProvider,
    ValidationObserver,
    BImg,
    SmsConfirm,
  },
  setup() {
    const toast = useToast()

    const authcode = ref(null)
    const phone = ref('')
    const step = ref(1)

    const validationForm = async () => {
      try {
        const isValid = authcode.value.validate()
        if (isValid) {
          await sendConfirmationCode(phone.value, 'auth')
          step.value = 2
        }
      } catch (error) {
        store.commit('app/SET_ERROR', error)
      }
    }

    const onResendCode = async () => {
      try {
        await sendConfirmationCode(phone.value, 'auth')
      } catch (error) {
        store.commit('app/SET_ERROR', error)
      }
    }

    const onSendAuth = async code => {
      try {
        if (!code || String(code).length !== 4) {
          throw new Error('Некорректный код подтверждения')
        }
        await sendAuthCode(phone.value, code)
        await fetchUserData()
        await fetchModeratorData()
        await chekbasket()
        await router.replace('/')
        toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Добро пожаловать ${store.state.user.userData.firstName}`,
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Вы успешно вошли в систему',
          },
        })
      } catch (error) {
        store.commit('app/SET_ERROR', error)
      }
    }

    return {
      authcode,
      phone,
      required,
      step,

      validationForm,
      onResendCode,
      onSendAuth,
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/innst-auth.scss';
</style>
